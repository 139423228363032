import React from "react"
import Layout from "../components/layout.js"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Link } from "gatsby"
import "../components/product.css"
import "../components/main.css"
import ProductCard from "../components/productCard"
import {
  createProductUrl,
  createBrandUrl,
  numberWithSpaces,
  capitalizeFirstLetter,
} from "../utilityFunctions.js"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ProductCompareText } from "../textGenerator.js"
import config from "../config.js"

export default function Product({ data, location, pageContext }) {
  let image = pageContext.image
  let product = pageContext.product
  let all_images = pageContext.all_images
  let similair_products = pageContext.similair_products
  let same_type_products = pageContext.same_type_products
  let same_brand_products = pageContext.same_brand_products
  let gatsby_settings = pageContext.gatsby_settings

  let productProperties = {}
  gatsby_settings.forEach(function (item, index) {
    if (item.node.category === "product_properties") {
      let values = item.node.value.split(";")

      let productPropertiesList = item.node.key.split(".")
      let current_node = product
      productPropertiesList.forEach(function (propKey, propIndex) {
        current_node = current_node[propKey]
      })
      productProperties[values[0]] =
        values[1] + String(current_node) + values[2]
    }
  })
  var frame_map_url = ""
  var frame_map_text = ""

  switch (product.derived_properties.frame_type) {
    case "herrcykel":
    case "damcykel":
      frame_map_url = "/cyklar/klassiska/"
      frame_map_text = "Klassiska cyklar"
      break
    case "hybridcykel, herr":
    case "hybridcykel, dam":
      frame_map_url = "/cyklar/hybridcyklar/"
      frame_map_text = "Hybridcyklar"
      break
    case "fatproduct":
      frame_map_url = "/cyklar/fatproducts/"
      frame_map_text = "Fatproducts"
      break
    case "hopfällbar":
      frame_map_url = "/cyklar/hopfallbara/"
      frame_map_text = "Hopfällbara"
      break
    case "lastcykel":
      frame_map_url = "/cyklar/lastcyklar/"
      frame_map_text = "Lastcyklar"
      break
    case "lådcykel":
      frame_map_url = "/cyklar/ladcyklar/"
      frame_map_text = "Lådcyklar"
      break
    case "mountainproduct":
      frame_map_url = "/cyklar/mountainproducts/"
      frame_map_text = "Mountainproducts"
      break
    case "racer":
      frame_map_url = "/cyklar/racers/"
      frame_map_text = "Racers"
      break
    case "singlespeed, dam":
    case "singlespeed, herr":
      frame_map_url = "/cyklar/singlespeeds/"
      frame_map_text = "Singlespeeds"
      break
    default:
      frame_map_url = "/cyklar/"
      frame_map_text = "Cyklar"
  }

  let type_li = ""
  if (config.siteName.toLowerCase() === "vilkencykel") {
    type_li = (
      <li>
        <b>Cykeltyp: </b>
        <Link to={frame_map_url} target="_blank">
          {frame_map_text}
        </Link>
      </li>
    )
  }
  const html_comment = {
    __html:
      '<!-- last_updated: "' +
      product.last_updated_date +
      '" --><!-- engine: "' +
      product.engine +
      '" -->',
  }

  let cardDetails = []
  gatsby_settings.forEach(function (item, index) {
    if (item.node.category === "card_details") {
      cardDetails.push(item.node)
    }
  })
  let miscSettings = {}
  gatsby_settings.forEach(function (item, index) {
    if (item.node.category === "misc") {
      miscSettings[item.node.key] = item.node.value
    }
  })

  return (
    <Layout
      title={product.full_name}
      location={location}
      pageContext={pageContext}
      addCrumbs={false}
      description={product.ai_description_short}
    >
      <h1>{product.full_name}</h1>
      <div dangerouslySetInnerHTML={html_comment} />
      <div className="singleProductWrapperHead">
        <div className="leftPane">
          <OutboundLink
            href={product.url}
            rel="nofollow noreferrer sponsored"
            target="_blank"
          >
            <div className="centeredImage reponsive_image">
              <GatsbyImage
                image={getImage(image)}
                alt={product.full_name}
                imgStyle={{ objectFit: "contain" }}
                loading="eager"
              />
            </div>
          </OutboundLink>
          <OutboundLink
            href={product.url}
            rel="nofollow noreferrer sponsored"
            target="_blank"
          >
            <div className="moreImages">Klicka här för fler bilder</div>
          </OutboundLink>
        </div>
        <div className="rightPane">
          <div className="price">{numberWithSpaces(product.prices[0])} kr</div>
          <div className="buyNow">
            <OutboundLink
              href={product.url}
              rel="nofollow noreferrer"
              target="_blank"
              className="buyLink"
            >
              Köp nu
            </OutboundLink>
          </div>
          <div className="descriptionSummary">
            {product.ai_description_summary}
          </div>
          <div className="summaryProperties">
            <ul>
              <li>
                <b>Märke: </b>
                <Link to={createBrandUrl(product.brand)} target="_blank">
                  {product.brand}
                </Link>
              </li>
              <li>
                <b>Modell: </b>
                <Link to={createProductUrl(product)} target="_blank">
                  {product.model}
                </Link>
              </li>
              {type_li}
              {/* TODO: Use a mapping to map the raw-keys to real keys, and add prefix/suffix e.t.c. Maybe add friendly_derived_properties (with suff/pre-fix and friendly key)*/}
              {Object.keys(productProperties).map(
                key =>
                  productProperties[key].substring(0, 4) !== "9999" && (
                    <li key={key}>
                      <b>{key}: </b>
                      {productProperties[key]}
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="singleProductWrapper">
        <div className="disclaimer">
          Denna sida innehåller affiliate-länkar till andra butiker. Detta
          betyder att vi får provision från butiken om du genomför ett köp, utan
          att det påverkar dig som kund.
        </div>
        <div
          className="shortDescription"
          dangerouslySetInnerHTML={{ __html: product.ai_description }}
        ></div>
        <h2>Liknande {config.productFriendlyName}</h2>
        Vi jämför alla {config.productFriendlyName} i vår databas baserat på
        deras egenskaper. Alla egenskaper är viktade och vissa egenskaper väger
        tyngre än andra. Nedan listar vi de {config.productFriendlyName}
        som är mest lika {capitalizeFirstLetter(product.full_name)}.
        <div className="productWrapper">
          <div className="productList">
            {similair_products.map(({ node }, index) => (
              <ProductCard
                key={node.id}
                product={node}
                allImageData={all_images}
                cardDetails={cardDetails}
                miscSettings={miscSettings}
              ></ProductCard>
            ))}
          </div>
        </div>
        <ProductCompareText
          product={product}
          compareProduct={similair_products[0].node}
          site={config.siteName}
        ></ProductCompareText>
        <h2>Fler liknande produkter</h2>
        <div className="productWrapper">
          <div className="productList">
            {same_type_products.map(({ node }, index) => (
              <ProductCard
                key={node.id}
                product={node}
                allImageData={all_images}
                cardDetails={cardDetails}
                miscSettings={miscSettings}
              ></ProductCard>
            ))}
          </div>
        </div>
        <h2>Fler produkter från {capitalizeFirstLetter(product.brand)}</h2>
        <div className="productWrapper">
          <div className="productList">
            {same_brand_products.map(({ node }, index) => (
              <ProductCard
                key={node.id}
                product={node}
                allImageData={all_images}
                cardDetails={cardDetails}
                miscSettings={miscSettings}
              ></ProductCard>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
